import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {tobeDark} from "./theme/tobeDark";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Login from "./pages/Login";

function App() {
  return (
      <ThemeProvider theme={tobeDark}>
          <CssBaseline/>
          <div className="App">
              <Routes>
                  <Route path='/login' element={<Login/>}/>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/2' element={<Page2/>}/>
                  <Route path='/3' element={<Page3/>}/>
              </Routes>
          </div>
      </ThemeProvider>
  );
}

export default App;
