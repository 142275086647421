import {Button, Link, Paper, Stack, TextField} from "@mui/material";
import logo from "../assets/tobe-blue-rgb.png";

const LoginForm = () => {
    return <Paper
                  sx={{
                      width: '35ch',
                      padding: '4ch 2ch'
                  }}>
        <Stack
            component="form"
            spacing={2}
        >
            <div style={{padding: '1rem'}}>
                <img src={logo} alt="ToBe" style={{width: '100%'}} />
            </div>
            <TextField id="email" label="Email" variant="outlined"/>
            <TextField id="password" label="Password" variant="outlined"/>
            <Link href="/home" underline="none">
                <Button variant="contained" color={"secondary"}>Accedi</Button>
            </Link>
        </Stack>
    </Paper>
}

export default LoginForm;
