import React, {useEffect, useState} from "react";
import StackedAreaChart from "../components/StackedAreaChart";
import axios from "axios";
import {Container, Grid, Paper, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import PieChart from "../components/PieChart";
import ToBeAppBar from "../components/AppBar";
import RadialBar from "../components/RadialBar";
import {BarChart} from "@mui/icons-material";
import BarChartNivo from "../components/BarChartNivo";
import GaugePlot from "../components/GaugePlot";
import HeatMap from "../components/HeatMap";
import {ResponsiveBar} from "@nivo/bar";
import nivoTheme from "../theme/nivoTheme";

function Home() {

    const moduleHeight = 400;
    const titleOffset = 50;

    const [days, setDays] = useState("7")

    useEffect(() => {
        getData()
    }, [days])

    const [data, setData] = useState([])
    const [consumiSistema, setconsumiSistema] = useState([])
    const [oreFunzionamento, setOreFunzionamento] = useState([])
    const [consumiPerCompressore, setConsumiPerCompressore] = useState([])
    const [qualitaDato, setQualitaDato] = useState([{id: 'quality', label: 'quality', value: '100'}])
    const [countModalitaFunzionamento, setCountModalitaFunzionamento] = useState({});
    const [modalitaFunzionamento, setModalitaFunzionamento] = useState([]);
    const [energyPerOperatingMode, setenergyPerOperatingMode] = useState([])

    function getData() {
        axios.get(`https://owwmomhbrl.execute-api.eu-central-1.amazonaws.com/dev/data/` + days)
            // axios.get(`http://localhost:8080/data/` + days)
            .then(res => {
                setData(res.data.caricoElettrico);
                setconsumiSistema(res.data.consumiSistema);
                setOreFunzionamento(res.data.oreFunzionamento);
                setConsumiPerCompressore(res.data.consumiPerCompressore);
                setCountModalitaFunzionamento(res.data.countModalitaFunzionamento);
                setModalitaFunzionamento(res.data.modalitaFunzionamento);
                setenergyPerOperatingMode(res.data.energyPerOperatingMode);
            })
    }

    return (
        <>
            <ToBeAppBar />
            <Container maxWidth="false">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                    <Typography style={{marginRight: '1rem'}}>Time range:</Typography>
                    <ToggleButtonGroup
                        value={days}
                        exclusive
                        onChange={(n, value) => setDays(value)}
                        aria-label="hours"
                        sx={{margin: '1rem 0'}}
                    >
                        <ToggleButton value="7" aria-label="7 days">
                            7d
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper>
                            <div style={{height: moduleHeight / 3}}>
                                <Typography sx={{pt: 2}}>Descrizione del sistema</Typography>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Paper sx={{py: 2}}>
                            <Typography>Consumi del sistema</Typography>
                            <div style={{height: moduleHeight - titleOffset}}>
                                <StackedAreaChart theme={nivoTheme} data={consumiSistema}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{py: 2}}>
                            <Typography>Carico elettrico del sistema</Typography>
                            <div style={{height: moduleHeight - titleOffset}}>
                                <StackedAreaChart theme={nivoTheme} data={data}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Misure in tempo reale</Typography>
                                <Typography>Il carico attuale del sistema è <b>0 kW</b>.</Typography>
                                <Typography>Il carico massimo del sistema è stato <b>0 kW</b>.</Typography>
                                <Typography>Il carico medio del sistema è stato <b>0 kW</b>.</Typography>
                                <Typography>Il carico minimo del sistema è stato <b>0 kW</b>.</Typography>
                            </div>
                        </Paper>
                    </Grid>


                    <Grid item xs={6} lg={3}>
                        <Paper sx={{py: 2}}>
                            <Typography>Ore di funzionamento</Typography>
                            <div style={{height: moduleHeight - titleOffset}}>
                                <RadialBar theme={nivoTheme} data={oreFunzionamento}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{py: 2}}>
                            <Typography>Consumi per compressore</Typography>
                            <div style={{height: moduleHeight - titleOffset}}>
                                <BarChartNivo theme={nivoTheme} data={consumiPerCompressore}/>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <Paper sx={{py: 2}}>
                            <Typography>Qualità media del dato</Typography>
                            <div style={{height: moduleHeight - titleOffset, position: "relative"}}>
                                <GaugePlot theme={nivoTheme} data={qualitaDato}/>
                                <p style={{position: "absolute", bottom: 10, left: 'calc(50% - 3rem)', fontSize: '2.5rem'}}>100%</p>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <Paper sx={{py: 2}}>
                            <Grid container>
                                <Grid item xs={12} lg={3}>
                                    <Typography>Modalità di funzionamento del sistema</Typography>
                                    <div style={{height: moduleHeight - titleOffset, position: "relative"}}>
                                        {Object.entries(countModalitaFunzionamento).map((e) => <p>{e[0]}: {e[1]}h</p>)}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <div style={{height: moduleHeight - titleOffset, position: "relative"}}>
                                        <HeatMap theme={nivoTheme} data={modalitaFunzionamento} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} lg={3}>
                        <Paper sx={{py: 2}}>
                            <Typography>Consumi per modalità di funzionamento</Typography>
                            <div style={{height: moduleHeight - titleOffset, position: "relative"}}>
                                <ResponsiveBar
                                    theme={nivoTheme}
                                    data={energyPerOperatingMode}
                                    keys={[
                                        'C1',
                                        'C2',
                                        'C3',
                                        'C1+C2',
                                        'C1+C3',
                                        'C2+C3',
                                        'C1+C2+C3',
                                    ]}
                                    margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
                                    padding={0.3}
                                    layout="vertical"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    role="application"
                                />
                            </div>
                        </Paper>
                    </Grid>


                </Grid>
            </Container>
        </>

    )
}

export default Home
