import nivoTheme from "../../../theme/nivoTheme";
import {ResponsiveBar} from "@nivo/bar";
import React from "react";

const WeekdayLoadProfileChart = ({theme, data /* see data tab */}) => (
    <ResponsiveBar
        theme={theme}
        data={data}
        keys={[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
        ]}
        indexBy="day"
        margin={{top: 50, right: 50, bottom: 50, left: 80}}
        padding={0.3}
        layout="horizontal"
        valueScale={{type: 'linear'}}
        indexScale={{type: 'band', round: true}}
        colors={{scheme: 'nivo'}}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        role="application"
    />
)

export default WeekdayLoadProfileChart;
