import React, {useEffect, useState} from "react";
import StackedAreaChart from "../components/StackedAreaChart";
import axios from "axios";
import {
    Box, Checkbox,
    Container,
    Grid, ListItemText,
    MenuItem, OutlinedInput,
    Paper, Select,
    SelectChangeEvent,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import PieChart from "../components/PieChart";
import ToBeAppBar from "../components/AppBar";
import RadialBar from "../components/RadialBar";
import {BarChart} from "@mui/icons-material";
import BarChartNivo from "../components/BarChartNivo";
import GaugePlot from "../components/GaugePlot";
import HeatMap from "../components/HeatMap";
import {ResponsiveBar} from "@nivo/bar";
import StackedChart from "../components/StackedChart";
import {ResponsiveLine} from "@nivo/line";
import nivoTheme from "../theme/nivoTheme";
import LoadCurveChart from "../components/charts/2/LoadCurveChart";
import WeekdayLoadProfileChart from "../components/charts/2/WeekdayLoadProfileChart";

function Page3() {

    const compressorList = [
        {
            id: 222030,
            name: 'Comp. ATLAS ZT 37 Kw (BOPA1)',
        },
        {
            id: 222045,
            name: 'Comp. ATLAS VSD 75 Kw (BOPA2)',
        },
        {
            id: 222060,
            name: 'Comp. ATLAS VSD 75 Kw (BOPA3)',
        }
    ]
    const moduleHeight = 375;
    const titleOffset = 50;

    const daysList = [
        {id: 1, name: 'Lunedì'},
        {id: 2, name: 'Martedì'},
        {id: 3, name: 'Mercoledì'},
        {id: 4, name: 'Giovedì'},
        {id: 5, name: 'Venerdì'},
        {id: 6, name: 'Sabato'},
        {id: 0, name: 'Domenica'},
    ]

    const [days, setDays] = useState("7")
    const [system, setSystem] = useState(222060)
    const [weekDays, setWeekDays] = useState([0, 1, 2, 3, 4, 5, 6])
    const [qualitaDato, setQualitaDato] = useState([{id: 'quality', label: 'quality', value: '100'}])
    const [kpis, setKpis] = useState()
    const [consumption, setConsumption] = useState([])
    const [weekdayConsumption, setWeekdayConsumption] = useState([])

    const apiUrl = 'https://owwmomhbrl.execute-api.eu-central-1.amazonaws.com/dev/';
    //const apiUrl = 'http://localhost:8080/';

    useEffect(() => {
        getData()
    }, [days, system])

    const [loadCurve, setLoadCurve] = useState([])

    function getData() {
        axios.get(apiUrl + `compare/kpis`)
            .then(res => {
                setKpis(res.data)
            });
        axios.get(apiUrl + `compare/consumption`)
            .then(res => {
                setConsumption(res.data)
            });
        axios.get(apiUrl + `compare/weekdayConsumption`)
            .then(res => {
                setWeekdayConsumption(res.data)
            });
    }

    const handleSystemChange = (event: SelectChangeEvent) => {
        setSystem(event.target.value);
    };

    const handleWeekDaysChange = (event: SelectChangeEvent) => {
        setWeekDays(event.target.value);
    };

    return (
        <>
            <ToBeAppBar/>
            <Container maxWidth="false">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                    <Typography style={{marginRight: '1rem', marginLeft: '2rem'}}>Time range 1:</Typography>
                    <ToggleButtonGroup
                        value={days}
                        exclusive
                        onChange={(n, value) => setDays(value)}
                        aria-label="hours"
                        sx={{margin: '1rem 0'}}
                    >
                        <ToggleButton value="7" aria-label="7 days">
                            -7d to now
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Typography style={{marginRight: '1rem', marginLeft: '2rem'}}>Time range 2:</Typography>
                    <ToggleButtonGroup
                        value={days}
                        exclusive
                        onChange={(n, value) => setDays(value)}
                        aria-label="hours"
                        sx={{margin: '1rem 0'}}
                    >
                        <ToggleButton value="7" aria-label="7 days">
                            -14d to -7d
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Typography style={{marginRight: '1rem', marginLeft: '2rem'}}>Giorni della settimana:</Typography>
                    <Select
                        multiple={true}
                        id="system-select"
                        value={weekDays}
                        label="Giorni"
                        input={<OutlinedInput label="Tag"/>}
                        renderValue={(selected) => selected.map(e => daysList[e].name).join(', ')}
                        onChange={handleWeekDaysChange}
                    >
                        {daysList.map(c => (
                            <MenuItem value={c.id}>
                                <Checkbox checked={weekDays.indexOf(c.id) > -1}/>
                                <ListItemText primary={c.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <Box container sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'start',
                    justifyContent: 'start'
                }}>

                    <Grid item xs={6} lg={3} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 2 * moduleHeight + titleOffset, textAlign: 'left', padding: '2rem'}}>
                                <Typography style={{textAlign: 'left'}}>
                                    Questo modulo permette di comparare i consumi medi orari dell'intero sistema di
                                    compressione tra due periodi di tempo.<br/><br/>
                                    Il periodo di riferimento rappresenta l'intervallo di tempo su cui calcolare degli
                                    indicatori di prestazione da comparare rispetto al periodo di confronto.<br/><br/>
                                    Il selettore filtro consente di includere o escludere del calcolo dell'indicatore di
                                    prestazione determinati gruppi di ore o giorni della settimana (e.g. giorni fuori
                                    produzione).<br/><br/>
                                    La variazione dell'indicatore di prestazione nel periodo di confronto rispetto al
                                    periodo di riferimento viene espressa in termini percentuali.
                                </Typography>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid container item xs={12} lg={9}>

                        <Grid item xs={12} lg={8} sx={{p: 1}}>
                            <Paper sx={{py: 1}}>
                                <div style={{minHeight: moduleHeight, textAlign: 'center', padding: '2rem'}}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            KPI CONFRONTO<br/><br/>
                                            NUMERO DI ORE: {7 * 24}<br/><br/>
                                            CONSUMO MEDIO
                                            ORARIO: <b>{kpis ? Math.round(kpis.totalFirst / (7 * 24)) : ''}</b> kWh<br/><br/>
                                            CONSUMO TOTALE: <b>{kpis ? kpis.totalFirst : ''}</b> kWh<br/><br/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            KPI CONFRONTO<br/><br/>
                                            NUMERO DI ORE: {7 * 24}<br/><br/>
                                            CONSUMO MEDIO
                                            ORARIO: <b>{kpis ? Math.round(kpis.totalSecond / (7 * 24)) : ''}</b> kWh<br/><br/>
                                            CONSUMO TOTALE: <b>{kpis ? kpis.totalSecond : ''}</b> kWh<br/><br/>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} lg={4} sx={{p: 1}}>
                            <Paper sx={{py: 1}}>
                                <div style={{minHeight: moduleHeight, textAlign: 'left', padding: '2rem'}}>
                                    <Typography>Qualità media del dato</Typography>
                                    <div style={{height: moduleHeight - 2 * titleOffset, position: "relative"}}>
                                        <GaugePlot theme={nivoTheme} data={qualitaDato}/>
                                        <p style={{
                                            position: "absolute",
                                            bottom: 10,
                                            left: 'calc(50% - 3rem)',
                                            fontSize: '2.5rem'
                                        }}>100%</p>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} lg={8} sx={{p: 1}}>
                            <Paper sx={{py: 1}}>
                                <div style={{minHeight: moduleHeight, textAlign: 'left', padding: '2rem'}}>
                                    <Typography style={{textAlign: 'left'}}>
                                    </Typography>
                                    <div style={{height: moduleHeight - 2 * titleOffset, position: "relative"}}>
                                        <ResponsiveBar
                                            groupMode="grouped"
                                            theme={nivoTheme}
                                            data={consumption}
                                            keys={[
                                                'first',
                                                'second',
                                            ]}
                                            indexBy="time"
                                            margin={{top: 20, right: 20, bottom: 20, left: 30}}
                                            padding={0.3}
                                            layout="vertical"
                                            valueScale={{type: 'linear'}}
                                            indexScale={{type: 'band', round: true}}
                                            colors={{scheme: 'nivo'}}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            role="application"
                                        />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} lg={4} sx={{p: 1}}>
                            <Paper sx={{py: 1}}>
                                <div style={{minHeight: moduleHeight, textAlign: 'left', padding: '2rem 1rem'}}>
                                    <Typography style={{textAlign: 'left'}}>
                                    </Typography>
                                    <div style={{height: moduleHeight - 2 * titleOffset, position: "relative"}}>
                                        <ResponsiveBar
                                            groupMode="grouped"
                                            theme={nivoTheme}
                                            data={weekdayConsumption}
                                            keys={[
                                                'first',
                                                'second',
                                            ]}
                                            indexBy="time"
                                            margin={{top: 15, right: 15, bottom: 20, left: 30}}
                                            padding={0.3}
                                            layout="vertical"
                                            valueScale={{type: 'linear'}}
                                            indexScale={{type: 'band', round: true}}
                                            colors={{scheme: 'nivo'}}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                            }}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            labelTextColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            role="application"
                                        />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>

                    </Grid>

                </Box>
            </Container>
        </>

    )
}

export default Page3
