import { ResponsiveLine } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const StackedAreaChart = ({ theme, data /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        theme={theme}
        margin={{ top: 50, right: 50, bottom: 60, left: 60 }}
        xScale={{ format: "%Y-%m-%dT%H:%M:%S%Z", type: "time" }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        enablePoints={false}
        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            format: "%b %d",
            orient: 'bottom',
            tickValues: "every 1 days",
            tickSize: 5,
            tickPadding: 8,
            tickRotation: 0,
            legend: 'Time',
            legendOffset: 40,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Power (kW)',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        enableArea={true}
        areaOpacity={0.1}
        useMesh={true}
        enableGridX={false}
        legends={[
            {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -30,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 220,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(255, 255, 255, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(255, 255, 255, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

export default StackedAreaChart;
