import {Grid} from "@mui/material";
import LoginForm from "../components/LoginForm";
import bg from '../assets/bg.jpeg';

const Login = () => {
    return <Grid container justifyContent="center" alignItems="center" sx={{width: 1, height: '100vh', background: "url(" + bg + ")"}}>
        <LoginForm/>
    </Grid>
}

export default Login;
