import { ResponsiveLine } from '@nivo/line'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LoadCurveChart = ({ theme, data /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        theme={theme}
        margin={{ top: 10, right: 30, bottom: 10, left: 50 }}
        xScale={{ type: 'linear' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        enablePoints={false}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 8,
            tickRotation: 0,
            legend: 'Percentage of Time',
            legendOffset: 40,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Power (kW)',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        enableArea={true}
        areaOpacity={0.1}
        useMesh={true}
        enableGridX={false}
    />
)

export default LoadCurveChart;
