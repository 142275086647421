import React, {useEffect, useState} from "react";
import StackedAreaChart from "../components/StackedAreaChart";
import axios from "axios";
import {
    Box,
    Container,
    Grid,
    MenuItem,
    Paper, Select,
    SelectChangeEvent,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import PieChart from "../components/PieChart";
import ToBeAppBar from "../components/AppBar";
import RadialBar from "../components/RadialBar";
import {BarChart} from "@mui/icons-material";
import BarChartNivo from "../components/BarChartNivo";
import GaugePlot from "../components/GaugePlot";
import HeatMap from "../components/HeatMap";
import {ResponsiveBar} from "@nivo/bar";
import StackedChart from "../components/StackedChart";
import {ResponsiveLine} from "@nivo/line";
import nivoTheme from "../theme/nivoTheme";
import LoadCurveChart from "../components/charts/2/LoadCurveChart";
import WeekdayLoadProfileChart from "../components/charts/2/WeekdayLoadProfileChart";

function Page2() {

    const compressorList = [
        {
            id: 222030,
            name: 'Comp. ATLAS ZT 37 Kw (BOPA1)',
        },
        {
            id: 222045,
            name: 'Comp. ATLAS VSD 75 Kw (BOPA2)',
        },
        {
            id: 222060,
            name: 'Comp. ATLAS VSD 75 Kw (BOPA3)',
        }
    ]
    const moduleHeight = 400;
    const titleOffset = 50;

    const [days, setDays] = useState("7")
    const [system, setSystem] = useState(222060)

    const apiUrl = 'https://owwmomhbrl.execute-api.eu-central-1.amazonaws.com/dev/';
    //const apiUrl = 'http://localhost:8080/';

    useEffect(() => {
        getData()
    }, [days, system])

    const [loadCurve, setLoadCurve] = useState([])
    const [loadAnalysis, setLoadAnalysis] = useState([])
    const [baselineLoad, setBaselineLoad] = useState([])
    const [loadProfile, setLoadProfile] = useState([])
    const [dailyConsumption, setDailyConsumption] = useState([])
    const [weeklyConsumption, setWeeklyConsumption] = useState([])

    function getData() {
        axios.get(apiUrl + `system/` + days + `/` + system + `/loadCurve`)
            .then(res => {
                setLoadCurve(res.data)
            });
        axios.get(apiUrl + `system/` + days + `/` + system + `/loadAnalysis`)
            .then(res => {
                setLoadAnalysis(res.data)
            });
        axios.get(apiUrl + `system/` + days + `/` + system + `/baselineLoad`)
            .then(res => {
                setBaselineLoad(res.data)
            });
        axios.get(apiUrl + `system/` + days + `/` + system + `/loadProfile`)
            .then(res => {
                setLoadProfile(res.data)
            });
        axios.get(apiUrl + `system/` + days + `/` + system + `/dailyConsumption`)
            .then(res => {
                setDailyConsumption(res.data)
            });
        axios.get(apiUrl + `system/` + days + `/` + system + `/weeklyConsumption`)
            .then(res => {
                setWeeklyConsumption(res.data)
            });
        // axios.get(`https://owwmomhbrl.execute-api.eu-central-1.amazonaws.com/dev/system/` + days + `/` + system)
        /*axios.get(apiUrl + `system/` + days + `/` + system)
            .then(res => {
                setLoadCurve(res.data.loadCurve);
                setLoadAnalysis(res.data.loadAnalysis);
                setBaselineLoad(res.data.baselineLoad);
                setLoadProfile(res.data.loadProfile);
                setDailyConsumption(res.data.dailyConsumption);
                if (res.data.weeklyConsumption.length > 0) {
                    setWeeklyConsumption(res.data.weeklyConsumption);
                }
            })*/
    }

    const handleSystemChange = (event: SelectChangeEvent) => {
        setSystem(event.target.value);
    };

    return (
        <>
            <ToBeAppBar />
            <Container maxWidth="false">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                    <Typography style={{marginRight: '1rem'}}>Sistema:</Typography>
                    <Select
                        id="system-select"
                        value={system}
                        label="Sistema"
                        onChange={handleSystemChange}
                    >
                        {/*<MenuItem value={0}>Tutto il sistema</MenuItem>*/}
                        {compressorList.map(c => (
                            <MenuItem value={c.id}>{c.name}</MenuItem>
                        ))}
                    </Select>

                    <Typography style={{marginRight: '1rem', marginLeft: '2rem'}}>Time range:</Typography>
                    <ToggleButtonGroup
                        value={days}
                        exclusive
                        onChange={(n, value) => setDays(value)}
                        aria-label="hours"
                        sx={{margin: '1rem 0'}}
                    >
                        <ToggleButton value="7" aria-label="7 days">
                            7d
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <Box container sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'start'}}>
                    <Grid item xs={12} sx={{p: 1}}>
                        <Paper>
                            <div style={{height: moduleHeight / 3}}>
                                <Typography sx={{pt: 2}}>Descrizione del sistema</Typography>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} lg={3} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Curva di durata del carico</Typography>
                                <div style={{height: 3 * moduleHeight / 4 - titleOffset}}>
                                    <LoadCurveChart theme={nivoTheme} data={loadCurve}/>
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={6} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Analisi del carico</Typography>
                                <div style={{height: 3 * moduleHeight / 4 - titleOffset}}>
                                    <ResponsiveLine
                                        data={loadAnalysis}
                                        theme={nivoTheme}
                                        margin={{ top: 50, right: 50, bottom: 60, left: 60 }}
                                        xScale={{ format: "%Y-%m-%dT%H:%M:%S%Z", type: "time" }}
                                        yScale={{
                                            type: 'linear',
                                            min: 'auto',
                                            max: 'auto',
                                            stacked: true,
                                            reverse: false
                                        }}
                                        enablePoints={false}
                                        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: "%b %d",
                                            orient: 'bottom',
                                            tickValues: "every 1 days",
                                            tickSize: 5,
                                            tickPadding: 8,
                                            tickRotation: 0,
                                            legend: 'Time',
                                            legendOffset: 40,
                                            legendPosition: 'middle'
                                        }}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Power (kW)',
                                            legendOffset: -40,
                                            legendPosition: 'middle'
                                        }}
                                        enableArea={true}
                                        areaOpacity={0.1}
                                        useMesh={true}
                                        enableGridX={false}
                                    />
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} lg={3} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{minHeight: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Analisi del carico di baseline</Typography>
                                <div style={{minHeight: 3 * moduleHeight / 4 - titleOffset}}>
                                    Il carico di baseline individuato è - kW<br/>
                                    Il carico di picco individuato è - kW<br/><br/>
                                    Il carico attuale del sistema è <b>{baselineLoad.current}</b> kW<br/>
                                    Il carico massimo del sistema è stato <b>{baselineLoad.max}</b> kW<br/>
                                    Il carico medio del sistema è stato <b>{baselineLoad.mean}</b> kW<br/>
                                    Il carico minimo del sistema è stato <b>{baselineLoad.min}</b> kW
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} lg={3} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Profili di carico</Typography>
                                <div style={{height: 3 * moduleHeight / 4 - titleOffset}}>
                                    <ResponsiveLine
                                        data={loadProfile}
                                        theme={nivoTheme}
                                        margin={{ top: 50, right: 50, bottom: 60, left: 60 }}
                                        xScale={{ format: "%Y-%m-%dT%H:%M:%S%Z", type: "time" }}
                                        yScale={{
                                            type: 'linear',
                                            min: 'auto',
                                            max: 'auto',
                                            stacked: true,
                                            reverse: false
                                        }}
                                        enablePoints={false}
                                        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: "%b %d",
                                            orient: 'bottom',
                                            tickValues: "every 1 days",
                                            tickSize: 5,
                                            tickPadding: 8,
                                            tickRotation: 0,
                                            legend: 'Time',
                                            legendOffset: 40,
                                            legendPosition: 'middle'
                                        }}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Power (kW)',
                                            legendOffset: -40,
                                            legendPosition: 'middle'
                                        }}
                                        enableArea={true}
                                        areaOpacity={0.1}
                                        useMesh={true}
                                        enableGridX={false}
                                    />
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={6} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Analisi dei consumi giornalieri</Typography>
                                <div style={{height: 3 * moduleHeight / 4 - titleOffset}}>
                                    <ResponsiveBar
                                        theme={nivoTheme}
                                        data={dailyConsumption}
                                        keys={[
                                            '1',
                                            '2',
                                            '3',
                                            '4',
                                            '5',
                                            '6',
                                            '7',
                                            '8',
                                        ]}
                                        indexBy="day"
                                        margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
                                        padding={0.3}
                                        layout="vertical"
                                        valueScale={{ type: 'linear' }}
                                        indexScale={{ type: 'band', round: true }}
                                        colors={{ scheme: 'nivo' }}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.6
                                                ]
                                            ]
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 8,
                                            tickPadding: 6,
                                            tickRotation: 0,
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    1.6
                                                ]
                                            ]
                                        }}
                                        role="application"
                                    />
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} lg={3} sx={{p: 1}}>
                        <Paper sx={{py: 1}}>
                            <div style={{height: 3 * moduleHeight / 4, textAlign: 'left', padding: '0 1rem'}}>
                                <Typography style={{textAlign: 'left'}} sx={{mb: 2, mt: 2}}>Profili di carico</Typography>
                                <div style={{height: 3 * moduleHeight / 4 - titleOffset}}>
                                    <WeekdayLoadProfileChart theme={nivoTheme} data={weeklyConsumption} />
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                </Box>
            </Container>
        </>

    )
}

export default Page2
