import {createTheme} from "@mui/material";

export const tobeDark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#04005E'
        },
        secondary: {
            main: '#FF2079'
        },
        background: {
            default: '#4889c6',
            paper: '#07005e'
        },
    },
    typography: {
        "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});
